import React from 'react';
import styles from './footer.module.css';

function Footer() {
    return (
        <div className={styles['footer']}>
            <span><a href="https://joelgray.work" >Gray Web - {new Date().getFullYear()}</a></span>
        </div>
    );
}

export default Footer;
