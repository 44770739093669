import React, { useEffect, useState, useContext } from 'react';
import { quoteContext } from '../../contexts/QuoteContext';
import { newQuoteContext } from "../../contexts/NewQuoteContext";
import './features.css';

function Features() {
  
  const {value} = useContext(quoteContext)
  const { data, setData } = useContext(newQuoteContext);
  const [quoteString, setQuoteString] = useState("...");
  const [sourceString, setSourceString] = useState("...");
  const [jsonData, setJsonData] = useState(null);
  
  const handleQuoteCopy = () => {
    const tempInput = document.createElement('input')
    console.log("Copying Quote: ", quoteString)
    tempInput.value = quoteString
    document.body.appendChild(tempInput)
    tempInput.select()
    document.execCommand('copy')
    document.body.removeChild(tempInput)
  };

  const handleJSONCopy = () => {
    const tempInput = document.createElement('input')
    console.log("Copying Data: ", JSON.stringify(jsonData))
    tempInput.value = JSON.stringify(jsonData)
    document.body.appendChild(tempInput)
    tempInput.select()
    document.execCommand('copy')
    document.body.removeChild(tempInput)
  };

  const handleClick = () => {
    let newVal = data + 1
    setData(newVal)
  }

  useEffect(() => {
    // fetch the initial quote data on component mount
    setJsonData(value)
    //hogwarts is context default state, do this so we dont print undefined but "..." instead until the quote loads
    if (value !== "hogwarts"){
      setQuoteString("\"" + value.quote + "\" - " + value.speaker)
      setSourceString("Source: " + value.story + " (" + value.source + ")")
    }
  }, [value]);
  

  return (
    <div className="content">
      <div>
        <h3 className="centered mb quote-main">{quoteString}</h3>
      </div>
      <div>
        <p className="centered mb quote-sub">{sourceString}</p>
      </div>
      <div className="centered">
            <button className="centered quote-sub" onClick={handleQuoteCopy}>Copy Quote</button>
            <button className="centered quote-sub" onClick={handleClick}>Accio Quote</button>
            <button className="centered quote-sub" onClick={handleJSONCopy}>Copy JSON</button>
      </div>
    </div>
  )
}
export default Features