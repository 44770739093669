import React, { useEffect, useState, useContext } from 'react';
import './main.css';
import { quoteContext } from '../../contexts/QuoteContext';
import { newQuoteContext } from "../../contexts/NewQuoteContext";
import bgImageDefault from '../../assets/hogwarts.jpg';
import { bgImages } from '../../assets/images.js';
const URL = 'https://api.portkey.uk/quote';

function Main() {
    const [bgImage, setImage] = useState(null);
    const {setValue} = useContext(quoteContext);
    const {data} = useContext(newQuoteContext);

    useEffect(() => {
        console.log("Quotes retrieved: ", data)
        refreshQuote()
    },[data]);

    const refreshQuote = () => {
        // fetch the quote data and set the state variables with the new data
        fetch(URL,{
            method: "GET",
            mode: "cors",
            headers: {
                 "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log("Quote data: ", data)
            console.log("ID: ", data.id)
            console.log("Speaker: ", data.speaker)
            console.log("Quote: ", data.quote)
            console.log("Story: ", data.story)
            console.log("Source: ", data.source)
            setValue(data)
            setBgImage(data.speaker)
        });
      }
    

    const setBgImage = (speaker) => {
        const fileName = formatStringLower(speaker)
        let foundFlag = false
        bgImages.forEach((image) => {
            if (fileName === image.name ) {
                setImage(image.image)
                foundFlag = true
            }
        });
        if (foundFlag === false) {
            setImage(bgImageDefault)
        }

    };

    const formatStringLower = (str) => {
            return str.replace(/\s+/g, '').toLowerCase();
        
    }
    return (
        <div className="--centered">
            <div>
                <img alt="Speaker" src={bgImage} id="homepage-background" />
            </div>
        </div>
    );
}


export default Main;
