import React from 'react';
import './header.css';

function Header() {
  return (
    <div className="header --centered">
        <div>
            <div className='text-content'>
                <h1 className="coming-soon centered mb">Wizarding Words</h1>
            </div>
        </div>
    </div>
);
}


export default Header