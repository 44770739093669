import lunalovegood from './lunalovegood.jpg'
import albusdumbledore from './albusdumbledore.jpg'
import dobby from './dobby.jpg'
import dracomalfoy from './dracomalfoy.jpg'
import horaceslughorn from './horaceslughorn.jpg'
import mollyweasley from './mollyweasley.jpg'
import siriusblack from './siriusblack.jpg'
import tomriddle from './tomriddle.jpg'
import hermionegranger from './hermionegranger.jpg'
import ronweasley from './ronweasley.jpg'
import gilderoylockhart from './gilderoylockhart.jpg'
import harrypotter from './harrypotter.jpg'
import rubeushagrid from './rubeushagrid.jpg'

export const bgImages = [
    {"name": "lunalovegood", "image":lunalovegood},
    {"name": "albusdumbledore", "image":albusdumbledore}, 
    {"name": "dobby", "image":dobby},  
    {"name": "dracomalfoy", "image":dracomalfoy}, 
    {"name": "horaceslughorn", "image":horaceslughorn}, 
    {"name": "mollyweasley", "image":mollyweasley}, 
    {"name": "siriusblack", "image":siriusblack}, 
    {"name": "tomriddle", "image":tomriddle}, 
    {"name": "hermionegranger", "image":hermionegranger}, 
    {"name": "ronweasley", "image":ronweasley}, 
    {"name": "harrypotter", "image":harrypotter}, 
    {"name": "gilderoylockhart", "image":gilderoylockhart},
    {"name": "rubeushagrid", "image":rubeushagrid}
]