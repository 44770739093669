import React, { useState } from "react";
import "./App.css";
import { Features, Footer, Header} from "./containers";
import { Main } from "./components";
import { quoteContext } from "./contexts/QuoteContext";
import { newQuoteContext } from "./contexts/NewQuoteContext";

function App() {
  const [value, setValue] = useState("hogwarts");
  const [data, setData] = useState(1);

  return (
    <div id="page-wrapper">
      <Header />
      <div id="page-content">
        <newQuoteContext.Provider value={{ data, setData }}>
        <quoteContext.Provider value={{ value, setValue }}>
          <Main />
          <Features />
        </quoteContext.Provider>
        </newQuoteContext.Provider>
      </div>
      <Footer />
    </div>
  );
}

export default App;
